<template>
  <div class="ch">
    <div class="dhl">
      <div class="dhl1">
        <img
          :src="fileServerUrl + '../assets/11.png'"
          alt=""
          @click="goHome"
          style="cursor: pointer"
        />
      </div>
      <!--    导航   -->
      <div class="dhl2">
        <span @click="counter1('#a1')" style="cursor: pointer">{{
          $t("Home")
        }}</span>

        <span @click="counter1('#a2')" style="cursor: pointer">{{
          $t("Latest")
        }}</span>

        <span @click="counter1('#a3')" style="cursor: pointer">{{
          $t("About")
        }}</span>

        <span @click="counter1('#a5')" style="cursor: pointer">{{
          $t("Brand")
        }}</span>

        <span @click="counter1('#a9')" style="cursor: pointer">{{
          $t("Project")
        }}</span>

        <span @click="counter1('#a7')" style="cursor: pointer">{{
          $t("Client")
        }}</span>

        <span @click="counter1('#a8')" style="cursor: pointer">{{
          $t("Contact")
        }}</span>

        <span class="EarthIcon" @click="changeLocale" style="cursor: pointer">{{
          language ? "EN" : "中"
        }}</span>
      </div>
    </div>
    <!--        动态背景-->
    <div>
      <div class="xdd" id="a1" style="margin-top: 40px">
        <!-- <video
          :src="fileServerUrl + 'assets/video1.mp4'"
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          id="sysp"
          style="width: 100%; height: 100%; margin-top: 4.1%"
        /> -->
        <!-- <a href="javascript:void(0)" @click="counter1('#a2')"></a>
        <svg
          t="1697212698541"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1455"
          width="200"
          height="200"
        >
          <path
            d="M510.919389 801.24466c-4.403287 0-8.809643-1.282203-12.622482-3.846608L73.471338 511.923252c-10.370185-6.971785-13.130042-21.032012-6.158257-31.406291 6.971785-10.375302 21.032012-13.135158 31.406291-6.163373l412.200016 276.993638 412.19797-276.993638c10.370185-6.971785 24.429389-4.217045 31.406291 6.163373 6.971785 10.374278 4.211928 24.434505-6.161327 31.406291L523.535731 797.398052C519.722892 799.963481 515.320629 801.24466 510.919389 801.24466L510.919389 801.24466zM510.919389 547.405371c-4.403287 0-8.809643-1.283226-12.622482-3.846608L73.471338 258.081916c-10.370185-6.971785-13.130042-21.030989-6.158257-31.406291 6.971785-10.379395 21.032012-13.133112 31.406291-6.161327l412.200016 276.992615 412.19797-276.992615c10.370185-6.976902 24.429389-4.211928 31.406291 6.161327 6.971785 10.375302 4.211928 24.434505-6.161327 31.406291L523.535731 543.558763C519.722892 546.122145 515.320629 547.405371 510.919389 547.405371L510.919389 547.405371zM510.919389 547.405371"
            fill="#fff"
            p-id="1456"
          ></path>
        </svg> -->
        <!-- <img :src="fileServerUrl + '../assets/hybg2.png'" alt="" /> -->
        <swiper :options="bannerOption">
          <swiper-slide>
            <img :src="fileServerUrl + '../assets/hybg.jpg'" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img :src="fileServerUrl + '../assets/banner_logo1.png'" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img :src="fileServerUrl + '../assets/banner_logo2.png'" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img :src="fileServerUrl + '../assets/banner_logo3.png'" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <!--      1.轮播控制-->
    <div class="lbkz" :style="getBjtp1('GwBg.jpg')">
      <div class="lbkz_top">
        <div class="bt_item">
          <span>{{ $t("Latest_Project") }}</span>
        </div>
      </div>
      <div class="lbkz_bottom">
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(6, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 7 || activeIndex == 0 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(6, 1000, false)"
        >
          <span>{{ $t("CHO") }}</span>
        </div>
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(1, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 2 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(1, 1000, false)"
        >
          <span>{{ $t("LogTalk") }}</span>
        </div>
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(5, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 6 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(5, 1000, false)"
        >
          <span>{{ $t("iMFG") }}</span>
        </div>
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(4, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 5 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(4, 1000, false)"
        >
          <span>{{ $t("Insight") }}</span>
        </div>
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(3, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 4 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(3, 1000, false)"
        >
          <span>{{ $t("PharmaX") }}</span>
        </div>
        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(2, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 3 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(2, 1000, false)"
        >
          <span>{{ $t("iTall") }}</span>
        </div>

        <div
          class="lbkz_item"
          @mouseover="
            khstop(6), $refs.mySwiper.$swiper.slideToLoop(0, 1000, false)
          "
          @mouseleave="khstart(6)"
          :class="{ lbkzactive: activeIndex == 1 || activeIndex == 8 }"
          @click="$refs.mySwiper.$swiper.slideToLoop(0, 1000, false)"
        >
          <span>{{ $t("SoldOut") }}</span>
        </div>
      </div>
    </div>

    <div id="a2" class="md1" style="background-color: rgb(234, 234, 234)"></div>

    <div
      style="
        background-color: rgb(234, 234, 234);
        width: 100%;
        padding-bottom: 50px;
      "
    >
      <div style="background-color: rgb(234, 234, 234); width: 100%">
        <div class="lb_zw"></div>
      </div>
      <div class="lb">
        <swiper
          class="swiper"
          :options="swiperOptions"
          @slideChange="slideChange"
          ref="mySwiper"
        >
          <!--零售轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <!-- <img
                  :src="fileServerUrl + 'assets/CHO.png'"
                  style="width: 100px"
                  alt=""
                /> -->
                <img :src="fileServerUrl + 'assets/logo-06.png'" alt="" />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("soldout_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.soldout.huiyuexpo.com/#/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/04.jpg'" alt="" />
            </div>
          </swiper-slide>
          <!--物流轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <img
                  :src="fileServerUrl + 'assets/logo-03.png'"
                  alt=""
                />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("logtalk_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.logtalk.huiyuexpo.com/#/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/project_2.png'" alt="" />
            </div>
          </swiper-slide>
          <!--科创轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <img :src="fileServerUrl + 'assets/logo-07.png'" alt="" />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("itall1_text") }}
                </p>
                <p>
                  {{ $t("itall2_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.itall.huiyuexpo.com/#/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/003v2.jpg'" alt="" />
            </div>
          </swiper-slide>
          <!--医药轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <!-- <img :src="fileServerUrl + 'assets/5logo_01.png'" alt="" /> -->
                <img
                  style="object-fit: contain"
                  :src="fileServerUrl + 'assets/logo-04.png'"
                  alt=""
                />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("pharmax1_text") }}
                </p>
                <p>
                  {{ $t("pharmax2_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.pharmax.huiyuexpo.com/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/004v3.jpg'" alt="" />
            </div>
          </swiper-slide>
          <!--金融轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <!-- <img :src="fileServerUrl + 'assets/5logo-02.png'" alt="" /> -->
                <img
                  style="object-fit: contain"
                  :src="fileServerUrl + 'assets/logo-02.png'"
                  alt=""
                />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("insight_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.insight.huiyuexpo.com/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/005v3.jpg'" alt="" />
            </div>
          </swiper-slide>
          <!--制造轮播-->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <img :src="fileServerUrl + 'assets/logo-05.png'" alt="" />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("imfg_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.imfg.huiyuexpo.com/">{{
                      $t("LearnMore")
                    }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/project_3.png'" alt="" />
            </div>
          </swiper-slide>
          <!-- CHO -->
          <swiper-slide class="slide">
            <div class="swiper_left">
              <div class="swiper_left_img">
                <img
                  :src="fileServerUrl + 'assets/logo-01.png'"
                  alt=""
                  style="object-fit: contain"
                />
              </div>
              <div class="swiper_left_p">
                <p>
                  {{ $t("CHO_text") }}
                </p>
              </div>
              <div class="swiper_left_ljgd">
                <button class="button" style="vertical-align: middle">
                  <span
                    ><a href="http://www.cho.huiyuexpo.com/">{{ $t("LearnMore") }}</a></span
                  >
                </button>
              </div>
            </div>
            <div class="swiper_right">
              <img :src="fileServerUrl + 'assets/project_1.png'" alt="" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <!--   关于我们   -->
    <div id="a3" class="md"></div>
    <div
      class="AboutUs"
      ref="aboutUsref"
      :class="{
        testAbout: true,
        testAboutenter: aboutShow,
        testAboutleave: !aboutShow,
      }"
    >
      <div class="Ab-font">
        <div class="Ab-font-title">
          <span>{{ $t("about_us") }}</span>
        </div>
      </div>
      <div class="AboutUs-wenzi">
        <br />
        <br />
        <p>
          {{ $t("about_us1_text") }}
        </p>
        <br />
        <p>
          {{ $t("about_us2_text") }}
        </p>

        <br />

        <br />
        <br />
      </div>
    </div>

    <!--    核心业务    -->
    <div id="a4" class="md"></div>
    <div
      class="core"
      ref="coreWork"
      :class="{
        testCore: true,
        testCoreenter: aboutCoreShow,
        testCoreleave: !aboutCoreShow,
      }"
    >
      <div class="core-total">
        <div class="core-title">
          <span>{{ $t("Core_business") }}</span>
        </div>

        <div class="core-wenzi">
          <div style="text-align: justify">
            <!--                        会誉相信，以客户视角审视概念到执行的个中流程，再加以多种创意，是团队为客户提供优质而高效服务的不二法宝。-->
            <!--                        会誉会展秉持为客户可持续创造价值的专业态度，得到了国内外众多客户的一致认可。 会誉会展目前的主要业务涵盖-->
            <!--                        以上四个领域。-->

            {{ $t("Core_business_text") }}
          </div>

          <div class="hr-style">
            <hr class="hr-dashed-fixed" />
          </div>
          <div class="core-lb">
            <div class="core-left">
              <div class="core-left-top">
                <div class="core-left-top-title">
                  <span class="core-span" v-if="activeIndexCore == 0">{{
                    $t("business1")
                  }}</span>
                  <span class="core-span" v-if="activeIndexCore == 1">{{
                    $t("business2")
                  }}</span>
                  <span class="core-span" v-if="activeIndexCore == 2">{{
                    $t("business3")
                  }}</span>
                  <span class="core-span" v-if="activeIndexCore == 3">{{
                    $t("business4")
                  }}</span>
                  &nbsp;&nbsp;
                  <span style="color: blue">{{
                    "0" + (activeIndexCore + 1)
                  }}</span>
                  <span>/04</span>
                </div>
                <div style="float: right; margin-top: -28px">
                  <span
                    @click="$refs.coreSwiper.$swiper.slidePrev(1000, false)"
                    :class="{ 'core-swiper-disable': activeIndexCore == 0 }"
                    class="iconfont six-lb-1-prev"
                    ><i class="el-icon-caret-left"></i
                  ></span>
                  <span
                    @click="$refs.coreSwiper.$swiper.slideNext(1000, false)"
                    :class="{ 'core-swiper-disable': activeIndexCore == 3 }"
                    class="iconfont six-lb-1-next"
                    ><i class="el-icon-caret-right"></i
                  ></span>
                </div>
              </div>
              <div class="core-left-middle">
                <div v-if="activeIndexCore == 0">
                  {{ $t("business1_text") }}
                </div>
                <div v-if="activeIndexCore == 1">
                  {{ $t("business2_text") }}
                </div>
                <div v-if="activeIndexCore == 2">
                  {{ $t("business3_text") }}
                </div>
                <div v-if="activeIndexCore == 3">
                  {{ $t("business4_text") }}
                </div>
              </div>
              <hr class="hr-dashed-fixed" />
              <div class="core-left-bottom">
                <div
                  @click="$refs.coreSwiper.$swiper.slideToLoop(0, 1000, false)"
                  class="core-left-bottom-item"
                  :class="{
                    'core-left-bottom-item-active': activeIndexCore == 0,
                  }"
                >
                  <img :src="fileServerUrl + 'assets/icon-01up.png'" alt="" />
                </div>
                <div
                  @click="$refs.coreSwiper.$swiper.slideToLoop(1, 1000, false)"
                  class="core-left-bottom-item"
                  :class="{
                    'core-left-bottom-item-active': activeIndexCore == 1,
                  }"
                >
                  <img :src="fileServerUrl + 'assets/icon-02up.png'" alt="" />
                </div>
                <div
                  @click="$refs.coreSwiper.$swiper.slideToLoop(2, 1000, false)"
                  class="core-left-bottom-item"
                  :class="{
                    'core-left-bottom-item-active': activeIndexCore == 2,
                  }"
                >
                  <img :src="fileServerUrl + 'assets/icon-03up.png'" />
                </div>
                <div
                  @click="$refs.coreSwiper.$swiper.slideToLoop(3, 1000, false)"
                  class="core-left-bottom-item"
                  :class="{
                    'core-left-bottom-item-active': activeIndexCore == 3,
                  }"
                >
                  <img :src="fileServerUrl + 'assets/icon-04up.png'" />
                </div>
              </div>
            </div>
            <div class="core-right">
              <swiper
                class="core-swiper"
                @slideChange="slideChangeCore"
                :options="swiperOptionsCore"
                ref="coreSwiper"
              >
                <swiper-slide class="core-slide">
                  <img :src="fileServerUrl + 'assets/4tu01.jpg'" alt="" />
                </swiper-slide>
                <swiper-slide class="core-slide">
                  <img :src="fileServerUrl + 'assets/4tu02.jpg'" alt="" />
                </swiper-slide>
                <swiper-slide class="core-slide">
                  <img :src="fileServerUrl + 'assets/4tu03.jpg'" alt="" />
                </swiper-slide>
                <swiper-slide class="core-slide">
                  <img :src="fileServerUrl + 'assets/4tu04.jpg'" alt="" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    品牌活动-->
    <div id="a5" class="md"></div>
    <span class="md"></span>
    <div class="brand-activity-title">
      <span>{{ $t("Brand_Events") }}</span>
    </div>
    <div class="brand_area">
      <Tilt
        class="tilt"
        :parallax="true"
        :options="tiltoptions"
        v-for="i in branddata"
        :key="i.id"
      >
        <div
          class="item"
          ref="card"
          @mousedown="branddown(i.id)"
          @mouseup="brandup(i.id)"
        >
          <img :src="i.img" :alt="i.title" />
          <p class="title">{{ $t(i.title) }}</p>
        </div>
      </Tilt>
    </div>
    <!--     项目回顾-->
    <div id="a9" class="md"></div>
    <div
      class="program-review"
      ref="aboutProgram"
      :class="{
        testAbout: true,
        testAboutenter: aboutPShow,
        testAboutleave: !aboutPShow,
      }"
    >
      <div class="program-review-title">
        <span>{{ $t("Project_Review") }}</span>
      </div>
      <div class="program-review-wenzi">
        <p>
          {{ $t("Project_Review_text") }}
        </p>
      </div>

      <div class="program-review-bottom">
        <div class="program-review-bottom-left">
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 4 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 4;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 4
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj4") }}</div>
              <!-- <div>保险科技峰会</div> -->
              <div>2024.11.7 {{ $t("proj4_place") }}</div>
            </div>
          </div>
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 5 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 5;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 5
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj5") }}</div>
              <!-- <div>金融科技峰会</div> -->
              <div>2024.9.27 {{ $t("proj5_place") }}</div>
            </div>
          </div>
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 6 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 6;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 6
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj6") }}</div>
              <!-- <div>零售物流与供应链峰会</div> -->
              <div>2024.6.27 {{ $t("proj6_place") }}</div>
            </div>
          </div>
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 1 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 1;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 1
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj1") }}</div>
              <div>2024.4.25 {{ $t("proj1_place") }}</div>
            </div>
          </div>
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 2 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 2;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 2
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj2") }}</div>
              <div>2024.3.21 {{ $t("proj2_place") }}</div>
            </div>
          </div>
          <div
            class="program-review-bottom-left-div"
            :class="
              xmhgTpActive == 3 ? 'program-review-bottom-left-div-active' : ''
            "
            @click="
              xmhgTpActive = 3;
              resetXmhglbTime();
            "
          >
            <div
              class="program-review-bottom-left-div-item"
              :class="
                xmhgTpActive == 3
                  ? 'program-review-bottom-left-div-item-active'
                  : ''
              "
            >
              <div>{{ $t("proj3") }}</div>
              <div>2024.3.15 {{ $t("proj3_place") }}</div>
            </div>
          </div>

          <div class="program-review-bottom-left-div">
            <div class="program-review-bottom-left-div-item" style="border: 0">
              <div>&nbsp;</div>
              <!-- <div>
                <a
                  href="https://ad.pdb2.com/l/CocLsG1SEpc6G0lt"
                  style="color: #fff"
                >
                  {{ $t("more_proj")
                  }}<i class="iconfont font-mini" style="margin-left: 5px"
                    >&#xe62b;</i
                  >
                </a>
              </div> -->
              <div>&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="program-review-bottom-right">
          <div v-if="xmhgTpActive == 1">
            <a href="https://mp.weixin.qq.com/s/q2bUEsZ8bJ-o4hGj78aZuw">
              <img :src="fileServerUrl + 'assets/pr1.jpg'" />
            </a>
          </div>
          <div v-if="xmhgTpActive == 2">
            <a href="https://mp.weixin.qq.com/s/8GkUE0dS4xtC0Rn36qpFIw">
              <img :src="fileServerUrl + 'assets/pr2.jpg'" />
            </a>
          </div>
          <div v-if="xmhgTpActive == 3">
            <a href="https://mp.weixin.qq.com/s/aBsNP01tA2-3nW9oyS5rOA">
              <img :src="fileServerUrl + 'assets/pr3.jpg'" />
            </a>
          </div>
          <div v-if="xmhgTpActive == 4">
            <a href="https://www.linkedin.com/events/chodigitaltalentsummit7210817581614530560/comments/">
              <img :src="fileServerUrl + 'assets/back_3.png'" />
            </a>
          </div>
          <div v-if="xmhgTpActive == 5">
            <a href="https://mp.weixin.qq.com/s/uOTAUgIEauEkur_gETvBqQ">
              <img :src="fileServerUrl + 'assets/back_2.png'" />
            </a>
          </div>
          <div v-if="xmhgTpActive == 6">
            <a href="https://mp.weixin.qq.com/s/eEjZGCVykRlRpd3J99j40Q">
              <img :src="fileServerUrl + 'assets/back_1.png'" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--    我们的客户-->
    <div id="a7" class="md"></div>
    <div class="we-client">
      <div class="we-client-title">
        <span>{{ $t("our_clients") }}</span>
      </div>
      <div class="we-client-swiper-div">
        <swiper :options="swiperOptionsWeClient">
          <swiper-slide>
            <img
              src="https://hyhzgw-1316759422.cos.ap-shanghai.myqcloud.com/assets/partner_swiper1.png"
              alt=""
            />
          </swiper-slide>
          <swiper-slide>
            <img
              src="https://hyhzgw-1316759422.cos.ap-shanghai.myqcloud.com/assets/partner_swiper2.png"
              alt=""
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div id="a8" style="height: 100px"></div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="title">
        <span>{{ $t("contact_us") }}</span>
      </div>
      <div class="form_flex">
        <div class="left">
          <h1>Contact Us</h1>
          <h3>{{ $t("请认真填写此表，提交后会有专人与您联系") }}</h3>
          <img :src="fileServerUrl + 'assets/contact2.gif'" alt="" />
        </div>
        <el-form
          status-icon
          :rules="rules"
          ref="ruleForm"
          :model="contactForm"
          label-position="top"
          style="width: 50%"
        >
          <el-form-item label="项目" prop="item">
            <el-select
              v-model="contactForm.item"
              placeholder="请选择想咨询的项目"
            >
              <el-option :label="$t('其他')" value="other"></el-option>
              <el-option :label="$t('零售')" value="soldout"></el-option>
              <el-option :label="$t('物流')" value="logtalk"></el-option>
              <el-option :label="$t('科创')" value="itall"></el-option>
              <el-option :label="$t('医药')" value="pharmax"></el-option>
              <el-option :label="$t('金融')" value="insight"></el-option>
              <el-option :label="$t('制造')" value="imfg"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('姓名')" prop="name">
            <el-input
              v-model="contactForm.name"
              :placeholder="$t('请输入您的姓名')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('公司')" prop="firm">
            <el-input
              v-model="contactForm.firm"
              :placeholder="$t('请输入您的公司')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('职位')" prop="post">
            <el-input
              v-model="contactForm.post"
              :placeholder="$t('请输入您的职位')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('联系电话')" prop="phone">
            <el-input
              v-model="contactForm.phone"
              :placeholder="$t('请输入您的电话')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('公司邮箱')" prop="email">
            <el-input
              v-model="contactForm.email"
              :placeholder="$t('请输入您的公司邮箱')"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="desc">
            <el-input
              type="textarea"
              autosize
              show-word-limit
              v-model="contactForm.desc"
              :placeholder="$t('请简单描述您的需求(选填)')"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="display: flex">
              <el-input
                v-model="code"
                :placeholder="$t('请输入验证码')"
                style="width: 25%; margin-right: 2px"
              ></el-input>
              <span
                @click="changeCode()"
                style="cursor: pointer"
                :title="$t('换一张')"
              >
                <identify :identifyCode="identifyCode"></identify
              ></span>
            </div>
            <el-button
              @click="submitForm('ruleForm')"
              :loading="submitLoading"
              >{{ $t("提交") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <GwFooter />
    <!--    回到顶部-->

    <div class="hddb" v-show="showHddb()">
      <!--  <span @click="counter1('#a1')" style="cursor: pointer;" >回到<br>顶部</span>-->
      <i @click="counter1('#a1')" class="iconfont">&#xe614;</i>
    </div>
    <div @click="drawer = true" class="el-icon-date" title="活动日历"></div>
    <el-drawer :title="$t('活动日历')" :visible.sync="drawer">
      <el-timeline>
        <el-timeline-item
          v-for="i in itemlist"
          :key="i.id"
          :timestamp="i.time.slice(0, 10)"
          placement="top"
        >
          <el-card>
            <div @click="go(i.url)" :title="i.title">
              <h4>{{ $t(i.title) }}</h4>
              <p>{{ i.time }}</p>
            </div>
            <img :src="i.img" alt="" />
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
  </div>
</template>

<script>
import identify from "../components/identify.vue";
import GwFooter from "@/components/GwFooter";
import axios from "axios";
import Tilt from "vanilla-tilt-vue";
export default {
  components: { GwFooter, identify, Tilt },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.xmhglb();
    var sysp = document.getElementById("sysp");
    sysp.play();
    this.enableKhlb();
    //刷新页面生成随机验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  created() {
    let m = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let now = m + "-" + day;
    switch (now) {
      case "1-1":
        this.blessings = "新年";
        break;
      case "1-22":
        this.blessings = "春节";
        break;
      case "4-5":
        this.blessings = "清明";
        break;
      case "5-1":
        this.blessings = "劳动";
        break;
      case "6-22":
        this.blessings = "端午";
        break;
      case "9-29":
        this.blessings = "中秋";
        break;
      case "10-1":
        this.blessings = "国庆";
        break;
      default:
        break;
    }
    if (this.date.includes(now)) {
      this.$notify({
        title: "会誉会展祝您:",
        message: this.blessings + "节快乐！",
        duration: 4500,
      });
    }
  },
  data() {
    return {
      bannerOption: {
        loop: true,
        autoplay: true,
        speed: 1000,
      },
      itemlist: [
        // {
        //   id: 1,
        //   img: fileServerUrl + "/assets/5logo-04.png",
        //   title: "SOLDOUT新引响峰会",
        //   time: "2023.7.20-21|上海",
        //   url: "http://www.soldout.tech/#/",
        // },
        {
          id: 2,
          img: fileServerUrl + "/assets/a11.png",
          title: "LOGTALK零售物流与供应链峰会",
          time: "2024.3.15|上海",
          url: "http://www.logtalk.huiyuexpo.com/#/",
        },
        {
          id: 3,
          img: fileServerUrl + "/assets/a8.jpg",
          title: "INsight保险洞察力峰会",
          time: "2024.3.21|北京",
          url: "http://www.insight.huiyuexpo.com/",
        },
        {
          id: 4,
          img: fileServerUrl + "/assets/CHO_new.png",
          title: "CHO数字人才驱动力峰会",
          time: "2024.4.25|上海",
          url: "",
        },
        {
          id: 5,
          img: fileServerUrl + "/assets/CHO_new.png",
          title: "金融新质人才力峰会",
          time: "2024.6.26|北京",
          url: "",
        },
        {
          id: 6,
          img: fileServerUrl + "/assets/5logo_01.png",
          title: "PharmaX未来医药峰会",
          time: "2024.6.27|上海",
          url: "http://www.pharmax.huiyuexpo.com/#/",
        },
      ],
      drawer: false,
      branddata: [
        { id: 1, img: fileServerUrl + "assets/a3.png", title: "零售" },
        { id: 2, img: fileServerUrl + "assets/a13.png", title: "营销" },
        { id: 3, img: fileServerUrl + "assets/a1.png", title: "快消" },
        { id: 4, img: fileServerUrl + "assets/a14.png", title: "地产" },
        {
          id: 5,
          img: fileServerUrl + "assets/a11(2).png",
          title: "物流与供应链",
        },
        {
          id: 6,
          img: fileServerUrl + "assets/a8.png",
          title: "金融与保险科技",
        },
        { id: 7, img: fileServerUrl + "assets/a17.png", title: "数字货币" },
        { id: 8, img: fileServerUrl + "assets/a10.png", title: "人力资源" },
        { id: 9, img: fileServerUrl + "assets/a6.png", title: "科创" },
        { id: 10, img: fileServerUrl + "assets/a7.png", title: "网络安全" },
        { id: 11, img: fileServerUrl + "assets/a4.png", title: "制造" },
        { id: 12, img: fileServerUrl + "assets/a12.png", title: "医药" },
        { id: 13, img: fileServerUrl + "assets/a15.png", title: "知识产权" },
        { id: 14, img: fileServerUrl + "assets/a5.png", title: "教育" },
        { id: 15, img: fileServerUrl + "assets/a9.png", title: "新能源" },
      ],
      tiltoptions: {
        reverse: false, // reverse the tilt direction

        max: 20, // max tilt rotation (degrees)

        startX: 0, // the starting tilt on the X axis, in degrees.

        startY: 0, // the starting tilt on the Y axis, in degrees.

        perspective: 1500, // Transform perspective, the lower the more extreme the tilt gets.

        scale: 1, // 2 = 200%, 1.5 = 150%, etc..

        speed: 300, // Speed of the enter/exit transition

        transition: true, // Set a transition on enter/exit.

        axis: null, // What axis should be disabled. Can be X or Y.

        reset: true, // If the tilt effect has to be reset on exit.

        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.

        glare: false, // if it should have a "glare" effect

        "max-glare": 1, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)

        "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise

        "mouse-event-element": null,

        gyroscope: true,

        gyroscopeMinAngleX: -45,

        gyroscopeMaxAngleX: 45,

        gyroscopeMinAngleY: -45,

        gyroscopeMaxAngleY: 45,
      },
      blessings: "",
      date: [
        //2023
        "1-1",
        "1-22",
        "4-5",
        "5-1",
        "6-22",
        "9-29",
        "10-1",
      ],
      code: "",
      identifyCode: "1234", //初始值
      identifyCodes: "1234567890", //验证码取值范围
      submitLoading: false,
      ruleForm: {
        name: "",
        phone: "",
        email: "",
        item: "",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        firm: [
          { required: true, message: "请输入您的公司", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        post: [
          { required: true, message: "请输入您的职位", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入合法的手机号",
            trigger: "blur",
          },
        ],
        item: [{ required: true, message: "请选择项目", trigger: "change" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        desc: [
          { required: false, message: "请输入描述", trigger: "blur" },
          {
            min: 5,
            message: "最少五个字",
            trigger: "blur",
          },
          {
            max: 100,
            message: "超出100字",
            trigger: "blur",
          },
        ],
      },
      contactForm: {},
      language: true,
      fileServerUrl: fileServerUrl,
      aboutShow: false,
      aboutCoreShow: false,
      aboutPShow: false,
      activeIndex: 1,
      activeIndexCore: 0,
      hxywTpHoverIndex: 0, //核心业务图片悬浮
      pphdTpHoverIndex: 0, //品牌活动图片悬浮
      xmhgTpActive: 1, //项目回顾点击的第几个项目
      xmhgTpActiveTime: 5, //切换到下一个倒计时
      khlbEnable: false, //是否可以操作客户轮播
      // swiper配置项:
      swiperOptions: {
        //效果,特效  默认为"slide"（普通位移切换），还可设置为"fade"（淡入）、"cube"（方块）、"coverflow"（3d流）、"flip"（3d翻转）
        effect: "slide",
        //最新项目速度
        speed: 1000,
        // 无限循环
        loop: true,
        // 自动滚动
        // autoplay: true, //默认的效果
        // 个性化配置
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        // 分页器: 官方的API文档是 HTML版本的, 与 vue 中用法有细微差异
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //响应点击事件
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      //核心业务
      swiperOptionsCore: {
        //效果,特效  默认为"slide"（普通位移切换），还可设置为"fade"（淡入）、"cube"（方块）、"coverflow"（3d流）、"flip"（3d翻转）
        effect: "slide",
        // 无限循环
        loop: false,
        // 自动滚动
        autoplay: false, //默认的效果
        // 个性化配置
        //autoResize : false,
        //自动调整大小
        slidesPerView: 1,
        // autoplay: {
        //     delay: 6000, //间隔时长, 单位毫秒
        //     disableOnInteraction: true, //用户操作swiper后是否禁用自动滚动
        // },
        // 分页器: 官方的API文档是 HTML版本的, 与 vue 中用法有细微差异
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //响应点击事件
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 我们的客户swiper配置项:
      swiperOptionsWeClient: {
        // slidesPerView: 6,
        // spaceBetween: 10,
        freeMode: true,
        speed: 10000,
        //效果,特效  默认为"slide"（普通位移切换），还可设置为"fade"（淡入）、"cube"（方块）、"coverflow"（3d流）、"flip"（3d翻转）
        effect: "slide",
        // 无限循环
        loop: true,
        // 自动滚动
        // autoplay: true, //默认的效果
        // 个性化配置
        autoplay: {
          delay: 0, //间隔时长, 单位毫秒
          disableOnInteraction: false, //用户操作swiper后是否禁用自动滚动
          stopOnLastSlide: false,
        },
        // 分页器: 官方的API文档是 HTML版本的, 与 vue 中用法有细微差异
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, //响应点击事件
        // },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      },
      swiperOptionsWeClient2: {
        slidesPerView: 6,
        spaceBetween: 10,
        freeMode: true,
        speed: 3000,
        //效果,特效  默认为"slide"（普通位移切换），还可设置为"fade"（淡入）、"cube"（方块）、"coverflow"（3d流）、"flip"（3d翻转）
        effect: "slide",
        // 无限循环
        loop: true,
        // 自动滚动
        // autoplay: true, //默认的效果
        // 个性化配置
        // autoplay: {
        //   delay: 0, //间隔时长, 单位毫秒
        //   disableOnInteraction: false, //用户操作swiper后是否禁用自动滚动
        //   stopOnLastSlide: false,
        //   reverseDirection: true,
        // },
        // 分页器: 官方的API文档是 HTML版本的, 与 vue 中用法有细微差异
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //响应点击事件
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    //维护中
    weihu() {
      this.$notify({
        title: "提示",
        message: "网站维护中",
        type: "warning",
      });
    },
    go(url) {
      window.open(url, "_blank");
    },
    branddown(id) {
      this.$refs.card[id - 1].style.transform = "scale(0.8)";
      this.$refs.card[id - 1].style.borderRadius = "30px";
    },
    brandup(id) {
      this.$refs.card[id - 1].style.transform = "scale(1)";
      this.$refs.card[id - 1].style.borderRadius = "0px";
    },
    //点击验证码刷新
    changeCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    //生成一个随机整数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    //随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },
    submitForm(formName) {
      if (this.code === "") {
        this.$message.info("请输入验证码");
      } else {
        if (this.code === this.identifyCode) {
          this.submitLoading = true;
          this.$refs[formName].validate((valid) => {
            if (valid) {
              axios.post("/send", this.contactForm).then((res) => {
                if (res.data.code === 200) {
                  this.$message.success("提交成功，稍后我们会联系您");
                  this.submitLoading = false;
                  this.$refs.ruleForm.resetFields();
                  this.changeCode();
                } else {
                  this.$message.info("异常，请稍后重试");
                  this.submitLoading = false;
                  this.changeCode();
                }
              });
            } else {
              this.$message.info("请检查是否填写完整");
              this.submitLoading = false;
              return false;
            }
          });
        } else {
          this.$message.info("验证码错误");
        }
      }
    },
    changeLocale() {
      if (this.language == true) {
        this.language = false;
        this.$i18n.locale = "en";
      } else {
        this.language = true;
        this.$i18n.locale = "zh";
      }
    },
    goHome() {
      window.location.href = "/";
    },
    scroll(value) {
      document.getElementById("scrollBox").scroll({
        left: 0,
        top: value,
        behavior: "smooth",
      });
    },
    onscroll() {
      const element = document.getElementById("scrollBox");
      element.onscroll = () => {
        this.realTimeList.forEach((e) => {
          const child = document.getElementById(e.type);
          if (element.scrollTop > child.offsetTop - 50)
            this.activeType = e.type;
        });
      };
    },
    counter1(item) {
      const returnEle = document.querySelector(item);
      if (!!returnEle) {
        returnEle.scrollIntoView(true);
      }
      document.querySelector(item).scrollIntoView(true);
      document.querySelector(item).scrollTop = 200;
      // this.scroll(document.getElementById(item).offsetTop - 15)
    },
    slideChange() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    slideChangeCore() {
      this.activeIndexCore = this.$refs.coreSwiper.$swiper.activeIndex;
    },
    mouseOver(e) {
      // console.log(e)
    },
    mouseLeave(e) {
      // console.log(e)
    },
    showHddb() {
      return document.documentElement.scrollTop >= 850;
    },
    handleScroll() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$refs.aboutUsref.offsetTop - 750) {
        //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.aboutShow = true;
      } else {
        this.aboutShow = false;
      }

      if (this.currentScroll >= this.$refs.coreWork.offsetTop - 750) {
        //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.aboutCoreShow = true;
      } else {
        this.aboutCoreShow = false;
      }

      if (this.currentScroll >= this.$refs.aboutProgram.offsetTop - 750) {
        //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.aboutPShow = true;
      } else {
        this.aboutPShow = false;
      }
    },
    resetXmhglbTime() {
      this.xmhgTpActiveTime = 7;
    },
    xmhglb() {
      if (this.xmhgTpActiveTime == 0) {
        this.xmhgTpActive = this.xmhgTpActive + 1;
        if (this.xmhgTpActive == 7) {
          this.xmhgTpActive = 1;
        }
        this.resetXmhglbTime();
      }
      if (this.xmhgTpActiveTime > 0) {
        setTimeout(() => {
          this.xmhgTpActiveTime = this.xmhgTpActiveTime - 1;
          this.xmhglb();
        }, 1000);
      }
    },
    //允许操作客户轮播倒计时
    enableKhlb() {
      if (!this.khlbEnable) {
        setTimeout(() => {
          this.khlbEnable = true;
        }, 3000);
      }
    },
    khstop(index) {
      if (!this.khlbEnable) {
        return;
      }
      if (index == 6) {
        this.$refs[`mySwiper`].$swiper.autoplay.stop();
      } else {
        this.$refs[`khlb` + index].$swiper.autoplay.stop();
      }

      // this.$refs.khlb1.$swiper.autoplay.stop();
      // this.$refs.khlb2.$swiper.autoplay.stop();
      // this.$refs.khlb3.$swiper.autoplay.stop();
      // this.$refs.khlb4.$swiper.autoplay.stop();
      // this.$refs.khlb5.$swiper.autoplay.stop();
    },
    khstart(index) {
      if (!this.khlbEnable) {
        return;
      }
      if (index == 6) {
        this.$refs[`mySwiper`].$swiper.autoplay.start();
      } else {
        this.$refs[`khlb` + index].$swiper.autoplay.start();
      }
      // this.$refs.khlb1.$swiper.autoplay.start();
      // this.$refs.khlb2.$swiper.autoplay.start();
      // this.$refs.khlb3.$swiper.autoplay.start();
      // this.$refs.khlb4.$swiper.autoplay.start();
      // this.$refs.khlb5.$swiper.autoplay.start();
    },
    getBjtp1(name) {
      let ys = {
        // backgroundImage: "url(" + this.fileServerUrl + "assets/" + name + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        // backgroundColor:'#524AF6FF',
        backgroundPosition: "center",
      };
      return ys;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  width: 38% !important;
  padding: 0 15px;
}
::v-deep .el-card {
  &:hover {
    transform: scale(0.98);
  }
  .el-card__body {
    position: relative;
    display: flex !important;
    div {
      cursor: pointer;
      flex: 9;
      z-index: 1;
    }
    img {
      position: absolute;
      width: 100px;
      bottom: 0;
      right: 0;
    }
  }
}
.el-icon-date {
  cursor: pointer;
  background-color: #ffffff;
  width: 28px;
  height: 28px;
  border-radius: 255px;
  font-size: 17px;
  color: #0238c0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1111;
  right: 12px;
  bottom: 70px;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
}
.md1 {
  margin-top: -40px;
  height: 0px;
}
.md {
  //padding-top: 60px;
  height: 120px;
  margin-top: -120px;
}
#a3 {
  height: 30px;
  margin-top: -30px;
}
#a5 {
  height: 30px;
  margin-top: -22px;
}
#a9 {
  height: 30px;
  margin-top: -30px;
}
#a7 {
  height: 30px;
  margin-top: -25px;
}

.top_e {
  position: fixed;
  right: 10px;
  bottom: 40px;
  background: rgba(204, 200, 255, 0.6);
  border-radius: 15px;
  cursor: pointer;
  display: none;
  width: 60px;
  height: 60px;
}
.top_e:hover {
  color: white;
  background: #1296db;
}

.template {
  scroll-behavior: smooth;
}

div {
  font-family: "PingFang SC", "Helvetica Neue", "Microsoft YaHei"; //使用字体
  //   color: #2f2f2f;
  letter-spacing: 0.3px;
  line-height: 12px;
}

.ch {
  scroll-behavior: smooth;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

.dhl {
  width: 100%;
  height: 30px;
  position: fixed;
  /*background: linear-gradient(to right, black, #c42021, black);*/
  background-color: #e7e8ec;
  z-index: 999;
}

.dhl1 img {
  width: 100px;
  height: 20px;
  display: flex;
  margin-left: 60px;
  margin-top: 5px;
}

.dhl2 {
  // width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 40%;
  /*margin-top: -10px;*/
  margin-top: -15px;
  font-family: weiruan;
  padding-right: 15px;
}

.dhl7 {
  float: left;
  position: absolute;
  width: 25px;
  margin-left: 5px;
  font-size: 8px;
  cursor: pointer;
  user-select: none;
  left: 690px;
  top: 10px;
}

.EarthIcon {
  width: 25px;
  color: #5c5c5c;
  font-family: weiruan;
  display: flex;
  align-items: center;
  justify-content: space-around;
  user-select: none;
}
.EarthIcon img {
  width: 9px;
  height: 9px;
}

.dhl2 span {
  color: #5c5c5c;
  //font-weight: bold;
  font-size: 8px;
  margin-left: 27px;
}

.dhl a {
  color: white;
}

.dhl2 span:hover {
  color: #0238c0;
}

.dhl7 span {
  color: #5c5c5c;
}

.dhl7 span:hover {
  color: #0238c0;
}

.kb1 {
  height: 20px;
}
.lb_zw {
  width: 540px;
  background-color: white;
  margin-left: 100px;
}
.lb {
  width: 540px;
  background-color: white;
  margin-left: 100px;
}

.lbkz {
  height: 90px;
  //background-color: #0000FFFF;
  //background-image: url("../assets/GwBg.jpg");
  background-size: 100%;
  margin-top: -2%;
  text-align: center;
  position: relative;
  background-color: rgb(234, 234, 234);
}

.lbkz_top {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  left: 45%;
  top: 15%;
}
.slide {
  padding-top: 15px;
}
.bt_item {
  margin-top: 10px;
  width: 100px;
  color: black;
  font-size: 0.17rem;
}
// .bt_item span {
//   // border-bottom: 2px solid black;
// }

.lbkz_bottom {
  position: relative;
  top: 50px;
  padding: 10px 0px;
  margin-left: 100px;
}

.lbkz_item {
  display: inline;
  float: left;
  // border-radius: 5px 5px 0 0;
  color: white;
  background-color: rgb(198 198 198);
  font-size: 10px;
  width: 27.15px;
  padding: 10px 25px;
  cursor: pointer;
}

//活跃的轮播按钮(点击或自动轮播到的位置)
.lbkzactive {
  color: black;
  background-color: #fff;
}

.swiper {
  margin-top: 40px;
  width: 100%;
  height: 220px;
}

.swiper_left {
  float: left;
  margin-left: 40px;
  width: 40%;
  height: 100%;
  position: relative;
}

.swiper_left_img {
  width: 100%;
  height: 25%;
  margin-left: 40%;
}
.swiper_left_img img {
  // width: 20%;
  height: 50%;
}

.swiper_left_p {
  color: #3d3d3d;
  margin-top: 4%;

  width: 100%;
  height: 45%;
  font-size: 0.09rem;
  font-family: "PingFang SC", "Helvetica Neue", "Microsoft YaHei";
  text-align: justify;
  //border: 0.1px solid red;
}

.button {
  display: inline-block;
  border-radius: 1px;
  border: 1px solid cornflowerblue;
  background-color: white;
  color: cornflowerblue;
  text-align: center;
  font-size: 0.1rem;

  width: 60px;
  height: 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;

  a {
    color: cornflowerblue;
  }
}

.button span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 7px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.swiper_left_ljgd {
  width: 100%;
  height: 19%;
  font-size: 0.14rem;
  color: white;
  position: absolute;
  left: 34%;
  bottom:14%;
}

.swiper_right {
  float: right;
  margin-right: 25px;
  width: 45%;
  height: 100%;
  overflow: hidden;
}

.swiper_right img {
  width: 100%;
  height: 90%;
  object-fit: cover;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.05);
  }
}

.swiper5 {
  width: 100%;
}

.swiper5 img {
  width: 750px;
  height: 400px;
}

//返回顶部
.hddb {
  position: fixed;
  right: 2%;
  bottom: 12%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 100%;
  //background-color: #fff;
  //box-shadow: 0px 1px 1px 0px #5c5c5c;
  border: 1px solid gainsboro;
  z-index: 1000;
  color: dimgray;
  display: flex;
  justify-content: center;
  align-items: stretch;
  cursor: pointer;
}

.hddb:hover {
  background-color: #e7e8ec;
}

.iconfont {
  cursor: pointer;
  margin-top: 12%;
  font-size: 0.15rem;
}

.one {
  display: flex;
}

.one1 {
  width: 60%;
}

.one2 {
  display: flex;
}

.one2 img {
  width: 80px;
  height: 40px;
  margin-left: 7px;
  margin-top: 2px;
  transform: scale(1);
  transition: 1s;
}

.one2 img:hover {
  transform: scale(1.2);
}

.one4 {
  width: 40%;
  display: flex;
  color: black;
  background: #f2f2f2;
}

.one4 p {
  width: 250px;
  font-size: 7px;
  margin-top: 10px;
  margin-left: 10px;
}

.one4:hover {
  background: black;
  color: white;
}
//关于我们
.testAboutenter {
  transform: translateY(0%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 1s ease;
}

.testAboutleave {
  transform: translateY(20%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 1s ease;
}

.testAbout {
  transform: translateY(20%); //滚动前的位置
  opacity: 0; //滚动前设置透明隐藏
}

.AboutUs {
  margin: 0 auto;
  margin-top: 25px;
  width: 100%;
}

.Ab-font {
  color: black;
  font-weight: bold;
  font-size: 0.17rem;
  margin-left: 335px; //back 340px
  text-align: center;
}

.Ab-font-title {
  width: 70px;
}
.Ab-font-title span {
  border-bottom: 3px solid #0238c0;
}

.showAbout-enter-active {
  animation: atgu 0.5s linear;
}

.AboutUs-wenzi {
  font-size: 0.09rem;
  line-height: 12px;
  margin: 0 auto;
  width: 580px;

  text-align: justify;
  //letter-spacing: 0.2px;
}
//核心业务
.testCoreenter {
  transform: translateY(0%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 1s ease;
}

.testCoreleave {
  transform: translateY(20%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 1s ease;
}
.testCore {
  transform: translateY(20%); //滚动前的位置
  opacity: 0; //滚动前设置透明隐藏
}
.core {
  height: 300px;
}
.core-total {
  //margin:0 auto;//back
  margin-left: 9%;
  width: 600px;
  //background-color:  #0238c0;  //back
  //border-radius: 10px;
  //border: 1px solid red;
}

.core-title {
  padding-top: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 0.17rem;
  margin-left: 4px;
  margin-top: 10px;
}
.core-title span {
  border-bottom: 3px solid #0238c0;

  color: black;
}

.core-font {
  color: white;
}

.line {
  display: inline-block;
  width: 43.5%;
  //border-top: 3px solid #ffffff;//back
  vertical-align: 5px;
}

.core-tp {
  margin-left: 60px; //back 10px

  margin-top: 25px;
  width: 100%;
  height: 80px;
  text-align: center;
}

.core-tp-item-div {
  float: left;
  width: 20%;
  border-right: 1px solid #2032a7;
}

.core-tp-item {
  float: left;
  display: inline;
  width: 80%; //back  24%
  height: 47px;
  padding: 10px 0px;
  margin-left: 12px; // back
  //border-right: 1px solid white;
  font-size: 8px;
  background-color: #2032a7; //back
  text-align: center;
  border: 0;
  box-shadow: 1px 1px 4px #424141;
}

.core-tp-item img {
  width: 20%;
  height: 20%;
  transition: all 1s;
  margin-bottom: 10px;
}
.core-tp-item img:hover {
  filter: none;

  transform: scale(1.5);
}

.core-text {
  width: 20%;
  border-bottom: 3px solid #0238c0;
  color: black; //back
  font-size: 15px; //back
}
.core-wenzi {
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 12px;
  height: 40px;
  font-size: 0.09rem;
  color: #434242; //back
}
.hr-style {
  margin-top: 5%;
}
.hr-dashed-fixed {
  border: 0;
  padding-top: 0.4px;
  background: repeating-linear-gradient(
    to right,
    #a2a9b6 9px,
    #a2a9b6 12px,
    transparent 0px,
    transparent 14px
  );
}
.core-lb {
  width: 96%;
  height: 200px;
  margin-left: 2%;
  margin-top: 5%;
  //border: 1px solid red;
  display: flex;
  justify-content: space-between;
}
.core-left {
  width: 40%;
  height: 100%;
  //border: 1px solid blue;
}

.core-right {
  width: 44%;
  height: 100%;
  //border: 1px solid blue;
}
.core-swiper-disable {
  color: #e7e8ec;
}

.core-slide {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  //border:0.1px solid red;
}
.core-slide img {
  width: 100%;
  border-radius: 10px;
  transition: all 1s;
  &:hover {
    transform: scale(1.05);
  }
}
.core-left-top {
  height: 30px;

  //border: 1px solid pink;
}
.core-span {
  border-bottom: 3px solid #0238c0;
  font-size: 0.17rem;
  font-family: weiruan;
}

.core-left-middle {
  margin-top: 7%;
  height: 60px;
  //border: 1px solid pink;
}
.core-left-bottom {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  //border: 1px solid pink;
  display: flex;
  justify-content: space-between;
}
.core-left-bottom-item {
  width: 17%;
  height: 72%;
  background-color: #c8c8c8;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
    background-color: #2637be;
    background-image: linear-gradient(90deg, #2637be 0%, #1000ff);
  }
  //border: 0.1px solid red;
}
.core-left-bottom-item img {
  width: 40%;
  height: 40%;
  margin-left: 29%;
  margin-top: 25%;
}
.core-left-bottom-item-active {
  background-color: #2032a6;
  &:hover {
    transform: translateY(0px);
    background-color: #2032a6;
  }
}

.brand-activity {
  margin-top: 40px;
  //margin-right: 1%;
  color: #fff;
  //品牌活动大背景
  //background-color: #0238c0;
}
.brand-activity-title {
  padding-top: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 0.17rem;
  color: black;
}
.brand-activity-title span {
  border-bottom: 3px solid #2637be;
}
.brand_area {
  margin: 30px auto;
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .tilt {
    cursor: pointer;
    width: 120px;
    margin-bottom: 10px;
    margin-right: 10px;
    .item {
      padding: 0 0 10px 0;
      color: #ffffff;
      font-weight: 600;
      width: 120px;
      background-color: #2637be;
      background-image: linear-gradient(90deg, #2637be 0%, #1000ff 100%);

      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale(1.03);
        transform-style: preserve-3d;
        .title {
          transform: scale(1.05);
          transform: translateZ(40px);
          opacity: 1;
        }
        img {
          transform: translateZ(50px);
          border-radius: 5px;
        }
      }
      .title {
        margin: 0;
        opacity: 0;
        transition: all 0.3s;
        font-size: 12px;
      }
      img {
        transition: all 0.3s;
        width: 90%;
      }
    }
  }
}
.program-review {
  margin-top: 2%;
  color: #333333;
  background-color: #fff;
}
.program-review-title {
  padding-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.17rem;
}
.program-review-title span {
  border-bottom: 3px solid #0239be;
}

.program-review-wenzi {
  color: #424141;
  width: 73%;
  font-size: 0.09rem;
  letter-spacing: 0.6px;
  margin-left: 15%;
  margin-top: 25px;
}
.program-review-bottom {
  width: 80%;
  height: 60%;
  margin-top: 20px;
}
.program-review-bottom-left {
  width: 21%;
  height: 60%;
  margin-left: 18.5%;
  text-align: left;
  background-color: #0238c0;
  color: #fff;
  float: left;
  font-size: 8px;
  border: 1px solid #0238c0;
}
.program-review-bottom-left-div {
  transition: all 0.5s;
  cursor: pointer;
  padding: 3px 10px;
  height: 33px;
}
.program-review-bottom-left-div-active {
  color: #0238c0;
  background-color: #ffffff;
}

.program-review-bottom-left-div-item {
  width: 85%;
  font-size: 0.09rem;
}
.program-review-bottom-left-div-item-active {
  border: 0px;
}
.program-review-bottom-right {
  display: flex;
  width: 60%;
  height: 60%;
  overflow: hidden;

  &:hover img {
    transform: scale(1.05);
  }
}
.program-review-bottom-right img {
  transition: all 1s;
  margin-left: 20px;
  height: 272px;
  // width: 420px;
  width: 100%;
  object-fit: cover;
}

.we-client {
  color: #333333;
  //margin-bottom: 40px;
  margin-top: 20px;
}
.we-client-title {
  padding-top: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 0.17rem;
}
.we-client-title span {
  border-bottom: 3px solid #0239be;
}
.we-client-swiper-div {
  margin: 30px auto 0 auto;
  //width: 100%;
  width: 70%;
  // height: 5%;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.we-client-swiper {
  margin-top: 20px;
  //margin-left: 15px;
  margin-right: 5px;
  width: 100%;
}
.we-client-swiper-slide {
  width: 10%;
  height: 5%;
  //border: 1px solid blue;
  // box-shadow: 1px 3px 3px rgba(92, 92, 92, 0.2);
  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 5px;
}
.we-client-swiper-slide img {
  width: 75%;
  height: 80%;
  //border:1px solid red;
  margin-left: 14px;
}
.swiper-wrapper {
  -moz-transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.main {
  display: flex;
}

.main11 {
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
}

.main123 {
  width: 60%;

  margin-top: -4px;
  background: #f2f2f2;
}

.main3 {
  width: 40%;

  margin-top: -4px;
}

.main3-1 {
  font-size: 7px;
  width: 250px;
  margin-top: 5px;
  padding: 10px;
}

.main3:hover {
  background: black;
  color: white;
  font-weight: bold;
}

.main31 {
  width: 400px;
  display: flex;
  font-size: 7px;
  margin-left: 20px;
  margin-top: 5px;
}

.main32 {
  display: flex;
  margin-left: 60px;
}

.main32 div {
  margin: 30px;
}

.main32 img {
  width: 20px;
  height: 20px;
}

.main32-1 span {
  font-size: 6px;
  margin-left: -1px;
}

.two {
  display: flex;
}

.two1 {
  width: 40%;
}

.two1-1 {
  font-size: 7px;
  width: 250px;
  margin-left: 10px;
  margin-top: 10px;
}

.two1:hover {
  background: black;
  color: white;
}

.two1 font {
  height: 40px;

  font-size: 12px;
  font-weight: bold;
}

.two2 {
  width: 60%;

  background: #f2f2f2;
}

.wrap img {
  width: 180px;
  height: 140px;
}

.two2-1 {
  margin-top: 5px;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2190px);
  }
}

.wrap {
  width: 440px;
  height: 140px;
  margin: 0 auto;
  overflow: hidden; /*不出框*/
}

.wrap ul {
  width: 3500px;
  list-style: none;
  display: flex;
  animation: 50s move infinite linear; /*一直轮播 不卡顿*/
}

.wrap ul li {
  width: 5%;
  text-align: center;
}

.wrap ul li img {
  width: 95%;
}

/*鼠标放在图上图片暂停*/
.wrap ul:hover {
  animation-play-state: paused;
}

.logo {
  display: flex;
}

.logo5 {
  display: flex;
  width: 40%;
  background: #f2f2f2;
}

.logo5:hover {
  background: black;
  color: white;
}

.logo5-1 {
  font-size: 7px;
  width: 250px;
  margin-top: 20px;
  margin-left: 10px;
}

.foot {
  width: 100%;

  background: black;
}

.foot2 {
  color: white;
  font-size: 1px;
  width: 420px;
  margin-left: 20px;
}

.foot3-3 {
  display: flex;
  margin-left: 10px;
}

.foot4 {
  font-size: 6px;
  margin-left: 130px;
  width: 100%;
}

.foot span:hover {
  color: #fd3e2b;
}

.foot3 {
  width: 500px;

  margin-left: 120px;
  display: flex;
}

.foot3-1 img {
  width: 10px;
  height: 10px;
  margin-left: 0px;
}

.foot3-2 {
  width: 300px;
  margin-left: 103px;
}

.foot3 span {
  width: 100px;
}

.foot3-3 span {
  width: 300px;
}

.foot5 {
  width: 600px;
  display: flex;
  margin-top: 6px;
  font-size: 6px;
  margin-left: 60px;
}

.foot5 div {
  margin-left: 70px;
}

.foot5 div span {
  width: 100px;
}

.foot5 img {
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-left: 60px;
}

.kb {
  height: 10px;
}

.one11 {
  width: 60%;
}

.one21 {
  display: flex;
}

.one21 img {
  width: 80px;
  height: 40px;
  margin-left: 7px;
  margin-top: 5px;
  transform: scale(0.8);
  transition: 1s;
}

.one21 img:hover {
  transform: scale(1);
}

.xdd {
  width: 100%;
  //height: 420px;
  position: relative;
  margin-bottom: -3px;
  a {
    display: block;
    position: absolute;
    height: 30px;
    width: 120px;
    z-index: 111;
    bottom: 65px;
    left: 70px;
  }
  svg {
    z-index: 111;
    position: absolute;
    bottom: 68px;
    width: 18px;
    height: 18px;
    left: 160px;
    animation: down 1s ease-out infinite;
  }
  @keyframes down {
    from {
      bottom: 65px;
    }
    to {
      bottom: 68px;
    }
  }
}

.dhgd-enter-active {
  //animation: atgu 0.5s linear;
  transition: opacity 2.4s linear;
}
.dhgd-enter,
.dhgd-leave-to {
  opacity: 0;
}
@keyframes atgu {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0px);
  }
}
.tp-font {
  position: absolute;
  z-index: 2;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 45px;
  width: 70%;
  height: 60%;
}

.xdd img {
  width: 100%;
  height: 100%;
  position: relative;
}

.ljgd {
  width: 100%;
  height: 19%;
  font-size: 12px;
  position: absolute;
  left: 80%;
}

/*.ljgd:hover{
        color: #0000FFFF;
        background-color: #fff;

    }*/
/*.ljgd:hover a{
      color: #6fb8f3;

    }*/
.font-mini {
  font-size: 8px;
}
.tab_active {
  border-bottom: 1px solid #ccc;
}
.contact {
  width: 80%;
  margin: 0 auto;
  .title {
    padding-bottom: 20px;
    padding-top: 12px;
    text-align: center;
    font-weight: bold;
    font-size: 0.17rem;
    span {
      border-bottom: 3px solid #0239be;
    }
  }
  .form_flex {
    display: flex;
    justify-content: space-between;
    .left {
      padding: 10px;
      color: #ffffff;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      width: 43%;
      background-color: #0239be;
      background-image: linear-gradient(90deg, #0239be 0%, #ffffff 100%);
      transition: all 0.5s;
      img {
        width: 90%;
      }
      h1 {
        font-size: 36px;
        margin-top: 20px;
        margin-bottom: 20px;
        transition: all 0.5s;
      }
      h3 {
        line-height: 25px;
      }
      &:hover {
        width: 45%;
      }
    }
  }
}
</style>
